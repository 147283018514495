import { GoogleIcon } from 'assets/icons/Social'
import { Input, Link } from 'components'
import { usePathname } from 'hooks'
import { LoginParams } from 'interfaces'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import {
  useCustomLogInMutation,
  useCustomSignUpMutation,
  useLoginMutation
} from 'services'
import { useLoginState } from 'state'
import { logger } from 'utils'

import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import { useGoogleLogin } from '@react-oauth/google'
import { tx } from '@twind/core'

import bgImage from './../assets/images/login-bg.svg'

const LoginPage = () => {
  const { isSchool } = usePathname()
  const router = useRouter()
  const { ref } = router.query
  const referral_code = typeof ref === 'string' ? ref : ''

  const { user } = useLoginState()

  const [referralCode, setreferralCode] = useState(referral_code || '')
  const [showPassword, setShowPassword] = useState(false)
  const [isLogin, setIsLogin] = useState(true)
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(false)

  const { mutateAsync: loginMutation, isLoading } = useLoginMutation()
  const { mutateAsync: customLogInMutation, isLoading: customLogInLoading } =
    useCustomLogInMutation()
  const { mutateAsync: customSignUpMutation, isLoading: customSignUpLoading } =
    useCustomSignUpMutation()

  const redirect = useMemo(() => {
    return typeof router.query.redirect === 'string'
      ? router.query.redirect
      : isSchool
      ? '/school'
      : '/dashboard'
  }, [isSchool, router.query.redirect])

  useEffect(() => {
    if (referral_code) {
      setreferralCode(referral_code)
    }
  }, [referral_code])

  useEffect(() => {
    if (user) router.push(redirect)
  }, [user, router, redirect])

  const onGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const payload: LoginParams = {
          type: 'google',
          code: tokenResponse.access_token,
          referral_code: referralCode,
          redirect,
          is_college_student: isSchool
        }

        logger('onGoogleLogin', payload)

        toast.promise(loginMutation(payload), {
          loading: 'Signing you in...',
          success: "You're logged in",
          error: 'An error occurred'
        })
      } catch (err) {
        logger('error', err)
      }
    }
  })

  const onHandleForm = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (isLogin) {
      if (!email || !password) {
        toast.error("Email and Password can't be empty!", {
          duration: 2000
        })
        return
      }

      const data = {
        email,
        password,
        remember_me: remember,
        login_method: 'manual',
        redirect,
        is_college_student: isSchool
      }

      try {
        logger('onCustomLogin', data)

        toast.promise(
          customLogInMutation(data),
          {
            loading: 'Signing you in...',
            success: "You're logged in",
            error: (err) => {
              if (err?.response?.data?.data?.attributes?.error) {
                logger('error', err?.response?.data?.data)
                return `${err?.response?.data?.data?.attributes?.error?.message}`
              } else {
                logger('error', err?.response?.data?.data)
                return 'An error occurred'
              }
            }
          },
          {
            duration: 4000
          }
        )
      } catch (err: any) {
        logger(
          'error',
          `${err?.response?.data?.data?.attributes?.error?.message}`
        )

        toast.error(
          `${err?.response?.data?.data?.attributes?.error?.message}`,
          {
            duration: 4000
          }
        )
      }
    } else {
      if (!userName || !email || !password) {
        toast.error("Name,Email and Password can't be empty!", {
          duration: 2000
        })
        return
      }

      const data = {
        name: userName,
        email,
        password,
        referral_code: referralCode,
        redirect,
        is_college_student: isSchool
      }

      try {
        logger('onCustomSignup', data)
        toast.promise(
          customSignUpMutation(data),
          {
            loading: 'Signing you in...',
            success: "You're logged in",
            error: (err) => {
              if (err?.response?.data?.data?.attributes?.error?.message) {
                logger('error', err?.response?.data?.data)
                return `${err?.response?.data?.data?.attributes?.error?.message}`
              } else {
                return 'An error occurred'
              }
            }
          },
          {
            duration: 4000
          }
        )
      } catch (err: any) {
        logger('error', err?.response?.data?.data?.attributes?.error?.message)

        toast.error(
          `${err?.response?.data?.data?.attributes?.error?.message}`,
          {
            duration: 4000
          }
        )
      }
    }
  }

  return (
    <div className="flex-1 flex flex-col justify-center items-center">
      <h1 className="sr-only">Login - Signup to Devsnest</h1>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-zinc-800 bg-opacity-50 text-zinc-900 shadow rounded-lg  mt-4 mb-12">
          <div className="w-full overflow-hidden rounded-t-lg">
            <button
              className={tx(
                !isLogin
                  ? 'bg-primary bg-opacity-10 text-white font-medium'
                  : 'text-zinc-300',
                'w-1/2 py-4 text-xl  text-center shadow-none focus:outline-none focus:z-10 overflow-hidden'
              )}
              onClick={() => {
                setIsLogin(true)
              }}>
              Log In
            </button>
            <button
              className={tx(
                isLogin
                  ? 'bg-primary bg-opacity-10 text-white font-medium'
                  : 'text-zinc-300',
                'w-1/2 py-4 text-xl  text-center shadow-none focus:outline-none focus:z-10 overflow-hidden'
              )}
              onClick={() => {
                setIsLogin(false)
              }}>
              Sign Up
            </button>
          </div>

          <div className="pb-12 px-8 md:px-12 space-y-8">
            <div className="mt-10 mb-4">
              <form className="flex flex-col gap-3" onSubmit={onHandleForm}>
                {!isLogin && (
                  <Input
                    className="bg-primary bg-opacity-10"
                    type={'text'}
                    label="Enter Name"
                    id="name"
                    placeholder=""
                    required
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value)
                    }}
                  />
                )}

                <Input
                  className="bg-primary bg-opacity-10"
                  type={'email'}
                  label="Enter email"
                  id="email"
                  placeholder=""
                  required
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />

                <div className="relative">
                  <Input
                    className="bg-primary bg-opacity-10"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    id="password"
                    placeholder=""
                    required
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                  />

                  <span className="absolute bottom-[12%] right-[8%] z-10 inline cursor-pointer">
                    {showPassword ? (
                      <EyeOffIcon
                        className="h-5 w-5 text-white"
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <EyeIcon
                        className="h-5 w-5 text-white"
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </span>
                </div>

                {isLogin && (
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-2 items-center">
                      <input
                        className="text-green-500 outline-none border-none cursor-pointer"
                        type="checkbox"
                        name="remember"
                        id="remember"
                        checked={remember}
                        onChange={(e) => {
                          setRemember(e.target.checked)
                        }}
                      />
                      <p className="text-xs text-zinc-300">Remember me</p>
                    </div>

                    <div
                      className="flex justify-center items-center hover:cursor-pointer py-1 px-1 bg-transparent text-xs text-zinc-300 hover:text-white rounded-md shadow-sm transition-colors focus:outline-none hover:bg-primary hover:bg-opacity-10"
                      onClick={() => {
                        router.push(`/reset-password`)
                      }}>
                      Forgot Password?
                    </div>
                  </div>
                )}

                <button
                  type="submit"
                  disabled={customLogInLoading || customSignUpLoading}
                  className="w-full flex justify-center items-center py-2 px-4 mt-2 rounded-md shadow-sm bg-indigo-600 hover:bg-indigo-700 text-sm font-medium text-white hover:text-zinc-200 transition-colors focus:outline-none disabled:opacity-50">
                  {isLogin
                    ? customLogInLoading
                      ? 'Getting you in...'
                      : 'Log In'
                    : customSignUpLoading
                    ? 'Getting you in...'
                    : 'Sign Up'}
                </button>
              </form>
            </div>

            <p className="flex items-center">
              <span className="flex-1 h-px bg-zinc-400" />
              <span className="mx-3 text-center text-zinc-300">OR</span>
              <span className="flex-1 h-px bg-zinc-400" />
            </p>

            <button
              onClick={() => onGoogleLogin()}
              disabled={isLoading}
              className="w-full flex justify-center items-center py-2 px-4 rounded-md shadow-sm bg-indigo-600 hover:bg-indigo-700 text-sm font-medium text-white hover:text-zinc-200 transition-colors focus:outline-none disabled:opacity-50">
              <GoogleIcon className="h-6 w-6" />
              <span className="ml-3">
                {isLoading ? 'Getting you in...' : 'Continue with Google'}
              </span>
            </button>

            <div>
              <div className="flex items-center my-6">
                <span className="flex-1 h-px bg-zinc-400" />
                <p className="mx-3 text-center text-zinc-300">
                  If you {"haven't"} already, also join us here
                </p>
                <span className="flex-1 h-px bg-zinc-400" />
              </div>

              <Link
                href="/discord"
                className="w-full flex justify-center items-center py-2.5 px-4 rounded-md shadow-sm bg-primary bg-opacity-10 hover:bg-opacity-20 text-sm font-medium text-white transition-colors focus:outline-none">
                Join our Discord Community &rarr;
              </Link>

              <Link
                href="/whatsapp"
                className="w-full flex justify-center items-center py-2.5 px-4 rounded-md shadow-sm bg-primary bg-opacity-10 hover:bg-opacity-20 text-sm font-medium text-white transition-colors focus:outline-none mt-3">
                Join our Whatsapp Group &rarr;
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

LoginPage.layout = {
  className: 'bg-center bg-cover',
  style: { backgroundImage: `url(${bgImage.src})` }
}

LoginPage.seo = {
  title: 'Login - Devsnest',
  description:
    'Join our Zero-Cost 7 months Full-Stack Bootcamp. Sign Up to to join the Free Coding Bootcamp and start your upskilling journey.',
  canonical: 'https://devsnest.in/login',
  openGraph: {
    url: 'https://devsnest.in/login'
  }
}

export default LoginPage
